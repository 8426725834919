import React from 'react'
import { FormattedMessage } from 'react-intl'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import LocalizedLink from '../../LocalizedLink/LocalizedLink'
import DeskImg from './assets/images/desk.svg'
import RestImg from './assets/images/rest.svg'

import './styles.scss';

const downloadFile = require('../codigo.pdf');

const GobCorpCards: React.FC = () => {

  return (
    <section className="my-layout-3">
      <div className="container">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-lg-5">
            <div className="card my-sm-n2 gobCorp__card">
              <div className="card-body d-flex">
                <div>
                  <h3 className="card-title-1 text-primary">
                    <FormattedMessage id="gobcorp.card_1.title" />
                  </h3>
                  <p className="card-text pb-3 text-primary lead">
                    <FormattedMessage id="gobcorp.card_1.description" />
                  </p>
                  <OutboundLink className="btn--basic" href={downloadFile}
                  target="_blank" rel="noreferrer">
                    <FormattedMessage id="gobcorp.card_1.btnPdf" />
                  </OutboundLink>
                </div>
                <img src={RestImg} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="card gobCorp__card">
              <div className="card-body d-flex">
                <div className="">
                  <h3 className="card-title py-layout-2 text-primary">
                    <FormattedMessage id="gobcorp.card_2.title" />
                  </h3>
                  <p className="card-text__card2 py-layout-3  text-primary lead">
                    <FormattedMessage id="gobcorp.card_2.description" />
                  </p>
                  <LocalizedLink to="/complaintschannel" className="btn--basic">
                    <FormattedMessage id="gobcorp.card_2.btn_link" />
                  </LocalizedLink>
                </div>
                <img src={DeskImg} alt="" />
              </div>
            </div>
          </div>
          <section className="certifications-container">
            <div className="card gobCorp__card">
              <div className="card-body d-flex">
                <div className="">
                  <h3 className="card-title py-layout-2 text-primary">
                    <FormattedMessage id="gobcorp.card_3.title" />
                  </h3>
                  <div className="cert-buttons">
                    <OutboundLink className="btn--basic" href="https://drive.google.com/file/d/10v3oeYB9bXaJ5DCQ83H55HiJurDZA3xS/view?usp=sharing" target="_blank" rel="noreferrer">
                      <FormattedMessage id="gobcorp.card_3.cert1" />
                    </OutboundLink>
                    <OutboundLink className="btn--basic" href="https://drive.google.com/file/d/158LTvzUVKrKNN3S9lbv62d9dfNDJhXsm/view?usp=sharing" target="_blank" rel="noreferrer">
                      <FormattedMessage id="gobcorp.card_3.cert2" />
                    </OutboundLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default GobCorpCards
