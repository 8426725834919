import * as React from 'react';
import IndexLayout from '../layouts';
import { graphql } from 'gatsby';
import LocalizedLink from '../components/LocalizedLink/LocalizedLink';
import Footer from '../components/Footer/Footer';
import Img, { FluidObject } from 'gatsby-image';
import GobCorpHero from '../components/GobCorp/GobCorpHero/GobCorpHero'
import { LocaleTypes } from '../data/locales';
import GobCorpCards from '../components/GobCorp/GobCorpCards/GobCorpCards';
import SEO from '../components/SEO/SEO';


interface GobProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const GobCorp: React.FC<GobProps> = ({location, pathContext}) => {

  const content = () => (
    <>
      <SEO titleId="gobcorp.hero.title" />
      <GobCorpHero />
      <GobCorpCards />
      <Footer theme="secondary" />    
    </>
  );
  return (
    <IndexLayout 
      render={content}
      locale={pathContext.localeCode}
      navbarTheme='default'
      location={location}/> 
  )
}

export default GobCorp
