import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'
// import './styles.scss'

const GobCorpHero: React.FC = () => {
  return (
    <section className="hero pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <h2 className="display-4 text-primary">
              <FormattedMessage id="gobcorp.hero.title"
                values={defaultFormatValues}/>
            </h2>
            <p className="lead mt-5">
              <FormattedMessage id="gobcorp.hero.description"
                values={defaultFormatValues}/>
            </p>
          </div>
        </div>
      </div>

    </section>
  )
}

export default GobCorpHero
